import React, { useEffect, useState } from "react";
import { Card, Button, Table, Tag, message,Tooltip,Modal } from "antd";
import { reqUserList, reqRoleList,reqDeleteUser } from "../../../../assets/api";
import CreateRoleModal from "./CreateRoleModal";
import UserSettingModal from "./UserSettingModal";
import SearchUser from './SearchUser';
import {localSet} from '../../../../assets/zkcyFunc';
import {MessageOutlined} from '@ant-design/icons';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function Account() {

  const currentUser = localSet.getUser();
  const [objUserList, setObjUserList] = useState({ page: 1, size: 20,ordering:'id'});
  const [data, setdata] = useState([]);
  const [total, settotal] = useState(0);
  const [rowuser, setrowuser] = useState();
  const [roles, setroles] = useState();
  const [objRoles, setObjRoles] = useState({});
  const [flag,setFlag] = useState(true)
  const [showModalA, setShowModalA] = useState(false);
  const [showModalB, setShowModalB] = useState(false);
  const [searchObj, setSearchObj] = useState({name__icontains:'',phonenumber__icontains:''});
  const [selectedData,setSelectedData] = useState();

  const columns = [
    {
      title: "序号",
      align:'center',
      // dataIndex: "id",
      fixed:'left',
      width:88,
      render:(text,record,index)=>(objUserList.page-1)*objUserList.size+index+1
  },
    {
      title: "用户名",
      dataIndex: "name",
    },
    {
      title: "手机号码",
      render: (record) => {
        if (!record.phonenumber) {
          return <span>未绑定</span>;
        } else {
          return record.phonenumber;
        }
      },
    },
    {
      title: "所在地",
      render: (record) => {
        return( 
          <div>
            <span>{record.province}</span>
            {record.city?<span>/{record.city}</span>:null}
            {record.district?<span>/{record.district}</span>:null}
          </div>
        )}
    },
    {
      title: "关联合作社",
      dataIndex: "hzsId",
    },
    {
      title: "创建日期",
      dataIndex: "create_time",
    },

    {
      title: "角色名称",
      render: (record) => {
        return objRoles[record.role_id.toString()];
      },
    },
    {
      title: "申请记录",
      render: (record) => {
        return <Tooltip title={record.show_message}>
          <span>{record.show_message?<MessageOutlined style={{color:'#f5222d'}} />:null}</span>
        </Tooltip>
      }
    },
    {
      title: "状态|操作",
      width:'96px',
      fixed:'right',
      render: (record) => {
        switch (record.available_state) {
          case 1:
            return (
              <Tag 
                style={{ color: "#52c41a", cursor: "pointer" }}
                onClick={() => setShowModalB(true)}
              >
                在用
              </Tag>
            )
          case 2:
            return (
              <Tag 
                style={{ color: "#fa541c", cursor: "pointer" }}
                onClick={() => setShowModalB(true)}
              >
                停用
              </Tag>
            )
          default:
            return <Tag color="magenta">禁用</Tag>;
        }
      },
    },
  ];

  const getUserList = async (data) => {
    if (currentUser.role_id<=3){
      data['city'] = currentUser.city
    }
    const result = await reqUserList(data);
    if (result.data.success) {
      const user = result.data.obj;
      setdata(user);
      settotal(result.data.count);
    } else {
      message.error("请求机构数据失败！");
    }
  };

  const transRolesAsObj = (data) => {
    if (data) {
      let result = {};
      data.forEach((item) => {
        Object.assign(result, JSON.parse(`{"${item.id}":"${item.name}"}`));
      });
      return result;
    }
  };

  const postDeleteUser =async (reqData) => {
    const result = await reqDeleteUser(reqData);
    if (result.data.success) {
        setFlag(!flag)
        message.success(result.data.message);
    } else {
      message.error(result.data.message);
    }        
}
  const deleteUser=()=> {
    if(selectedData){
        confirm({
          title: '您确定要删除该用户吗',
          icon: <ExclamationCircleOutlined />,
          cancelText:'取消',
          okText:'确定',
          onOk() {
            postDeleteUser({user_ids:selectedData.toString()})
          },
          onCancel() {},
        });
    }else{
        message.error('请选择用户')
    }
  }

  const getRoleList = async () => {
    const result = await reqRoleList();
    if (result.data.success) {
      setroles(result.data.obj);
      setObjRoles(transRolesAsObj(result.data.obj));
    } else {
      message.error("请求机构数据失败！");
    }
  };

  console.log(objUserList);
  useEffect(() => {
    getRoleList();
    getUserList(Object.assign(objUserList ,{...searchObj}));
    // eslint-disable-next-line
  }, [objUserList.page,objUserList.size,flag]);

  const title = (
    <div style={{display:'flex',justifyContent:'space-between'}}>
      <SearchUser 
          searchObj={searchObj}
          setSearchObj={setSearchObj}
          flag={flag}
          setFlag={setFlag}
      />
      <div>
        <Button
          type="primary"
          onClick={() => setShowModalA(true)}
          style={{ marginRight: "10px" }}
        >
          + 创建用户
        </Button>
        <Button onClick={deleteUser} style={{marginLeft:'16px'}} danger >删除</Button>

      </div>
    </div>
  );

  const pageonChange = (page, pageSize) => {
    setObjUserList({ ...objUserList,page:page,size:pageSize });
  };
  const showTotal = () => {
    return "总数:" + total + "条";
  };
  const onRow = (val) => {
    return {
      onClick: (event) => {
        setrowuser({...val,roletitle:objRoles[val.role_id.toString()]});
      },
    };
  };
  const rowSelection = {
    onSelect:(record, selected, selectedRows)=>{
      const selectId = selectedRows.map((item,index)=>{
          return item.id
      })
      setSelectedData(selectId)
    },
    onSelectAll:(selected, selectedRows)=>{
        const selectId = selectedRows.map((item,index)=>{
            return item.id
        })
        if(selectId.length!==0){
            setSelectedData(selectId)
        }else{
            setSelectedData()
        }
    },
    type: "checkbox",
  };

  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card title={title} bordered={false}>
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            bordered={true}
            onRow={onRow}
            size={'small'}
            scroll={{ x: 1200}}
            pagination={{
              defaultPageSize: objUserList.size,
              current:objUserList.page,
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              total: total,
              onChange: pageonChange,
              onShowSizeChange: pageonChange,
              showTotal: showTotal,
            }}
          />
          <Modal
            title="创建角色"
            visible={showModalA}
            footer={null}
            centered={true}
            width={400}
            onCancel={() => setShowModalA(false)}
          >
            <CreateRoleModal 
              roles={roles} 
              setShowModalA={setShowModalA}
              flag={flag}
              setFlag={setFlag} 
            />
          </Modal>
          <Modal
            destroyOnClose={true}
            title="用户设置"
            visible={showModalB}
            footer={null}
            centered={true}
            width={400}
            onCancel={() => setShowModalB(false)}
          >
            <UserSettingModal
              currentUser={currentUser.name}
              currentUserRoleName={objRoles[currentUser.role_id.toString()]}
              setObjUserList={setObjUserList}
              objUserList={objUserList}
              getUserList={getUserList}
              rowuser={rowuser}
              roles={roles}
              setShowModalB={setShowModalB}
              setFlag={setFlag}
              flag={flag}
            />
          </Modal>
        </Card>
      </div>
    </div>
  );
}
