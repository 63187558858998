import React from 'react'
import { Button } from 'antd';

import { Card } from 'antd';

const { Meta } = Card;



export default function Report() {

    return (
        <div>
            <div style={{display:"flex",justifyContent:"space-between"}}>
            <Card
            hoverable
            style={{ width: 240 }}
            cover={<img  alt="example" src="https://www.zkcyxx.com/hzs/media/report1.jpg" />}
        >
            <Meta title="报告1" description="合作社年度报告" />
            </Card>,

            <Card
            hoverable
            style={{ width: 240 }}
            cover={<img  alt="example" src="https://www.zkcyxx.com/hzs/media/report1.jpg" />}
        >
            <Meta title="报告2" description="龙头合作社分析报告" />
            </Card>,

            <Card
            hoverable
            style={{ width: 240}}
            cover={<img  alt="example" src="https://www.zkcyxx.com/hzs/media/report1.jpg" />}
        >
            <Meta title="报告3" description="合作社发展报告" />
            </Card>,
            </div>
            <Button type='primary' size='large' style={{width:"100%"}}>
                
            <a href='https://www.zkcyxx.com/hzs/media/hzsmodel.pdf' rel="noopener noreferrer" target='_blank'>
            生成报告
            </a>
            </Button>
        </div>
    )
}
