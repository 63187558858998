import { useState, useEffect } from 'react';
import { createModel } from "hox";

// 【HoxTools】-【currentWindowWidth】当窗口发生变化时，实时计算并返回当前视口宽度值 
const useViewport = () => {
  //处理兼容
  let bowhidden="hidden" in document?"hidden": "webkithidden" in document?"webkithidden": "mozhidden" in document ?"mozhidden": null;
  let vibchage="visibilitychange" || "webkitvisibilitychange" || "mozvisibilitychange";

  const [currentWindowWidth, setWidth] = useState(window.innerWidth);
  const [currentWindowHeight, setHeight] = useState(window.innerHeight);

  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleWindowResize = () =>{
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    const handleIsActive = () =>{
      setIsActive(!document[bowhidden])
    };

    window.addEventListener("resize", handleWindowResize);
    document.addEventListener(vibchage,handleIsActive);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
      document.removeEventListener(vibchage,handleIsActive);
    }

  }, [bowhidden,vibchage]);

  return { currentWindowWidth,currentWindowHeight,isActive };
};
export const HoxViewport = createModel(useViewport);  


// 定义 login 组件 显示modalIndex
const useLogin = () => {
  const [modalIndex,setModalIndex] = useState(1); 
  const [loginUser,setLoginUser] = useState({}); 

  return {
      modalIndex,setModalIndex,
      loginUser,setLoginUser
  }
}
export const HoxLogin = createModel(useLogin);