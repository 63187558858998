/*
发送 ajax 请求的函数模块
封装 axios 库
函数返回 promis 对象
参数：url，data={}，type="GET"
*/
// import React from 'react';
import axios from 'axios';
import { message } from 'antd';

function ajax(url, data = {}, type = "GET",headers) {
    // 优化处理：
    // 1.在外层新创建一个promise对象，用来统一处理请求异常；
    return new Promise((resolve, reject) => {
        // 2.执行异步ajax请求
        let promise;
        if ( type === 'GET' ) {
            promise = axios.get(url, {
                params : data
            })
        }
        else {
            promise = axios.post( url,data,{headers:headers} )
        }
        promise.then(response => {
            // 3.处理请求正常的结果；
            resolve ( response )
            // 4.请求出错时，不调用rejec（error），统一直接显示错误信息
        }).catch( error =>{
            // message.error('请求失败：'+ error.message)
        })

    });
    
}

//接口列表
            
// 登录用户校验        
export const reqLogin = (data)=> ajax('/hzs/api/web/login',data,'POST' )
// 获取用户列表
export const reqUserList = (data)=> ajax('/hzs/api/userinfo',data )
// 快速创建管理员
export const reqAdminCreat = (data)=> ajax('/hzs/api/userinfo/create',data,'POST' )
// 编辑用户信息
export const reqUserUpdata = (data)=> ajax('/hzs/api/userinfo/update',data,'POST' )
// 删除用户
export const reqUserDelete = (data)=> ajax('/hzs/api/userinfo/delete',data,'POST' )



// 获取角色列表
export const reqRoleList = (data)=> ajax('/hzs/api/role',data )
// 创建角色
export const  reqRoleCreate = (data)=> ajax('/hzs/api/role/create',data,'POST')
// 更新角色权限
export const  reqRoleUpdatePermission = (data)=> ajax('/hzs/api/role/update',data,'POST')
// 删除角色
export const  reqRoleDelete = (data)=> ajax('/hzs/api/role/delete',data,'POST')



// 获取合作社列表
export const  reqHzsList = (data)=> ajax('/hzs/api/orgdetail/',data)
//导出EXCEL
export const  reqExcelList = (data)=> ajax('/hzs/api/importorgdetail/',data)
// 获取聚类结果
export const  reqGroupAll = (data)=> ajax('/hzs/api/group/all',data)
// 获取图表聚类结果
export const  reqEchartsGroupAll = (data)=> ajax('/hzs/api/echarts/groupall',data)
//删除合作社
export const  reqDeleteHzs = (data)=> ajax('/hzs/api/delete/org',data,'POST')
//删除用户
export const  reqDeleteUser = (data)=> ajax('/hzs/api/delete/user',data,'POST')
//局部更新部分合作社
export const  reqPartup = (data)=> ajax('/hzs/api/partup/org',data,'POST')

//文件导入
export const  reqUploadFile = (data)=> ajax('/hzs/api/web/uploadfile',data,'POST')
//上传基地照片
export const  reqUploadJdzp = (data)=> ajax('/hzs/api/submit/step4/jdzp',data,'POST')
//删除基地照片
export const  reqDeleteJdzp = (data)=> ajax('/hzs/api/delete/jdzp',data,'POST')
//上传营业执照接口
export const  reqUploadYyzz = (data)=> ajax('/hzs/api/submit/step1/yyzz',data,'POST')
//删除基地照片
export const  reqDeleteYyzz = (data)=> ajax('/hzs/api/delete/yyzz',data,'POST')

//修改密码
export const  reqChangePassword= (data)=> ajax('/hzs/api/web/changepassword',data,'POST')