export const bannerDetails = [
    {
        key: '1',
        style: { minHeight:'420px',backgroundColor:'rgba(2, 117, 196, 0.8)'},
        src: 'http://82.156.100.210/hzs/media/sxhzs/topic1.png',
        title: '陕西农民合作社信息宣传服务平台',
        details: "2022年陕西农民合作社信息填报进行中",
        path: '/login'
      },
    {
        key: '2',
        style: { minHeight:'420px',backgroundColor:'rgba(159, 71, 153, 0.8)'},
        src: 'http://82.156.100.210/hzs/media/sxhzs/topic_3.png',
        title: '陕西农民合作社风采展示',
        details: '龙头企业、省级示范社',
        path: '/discipline'
    },
    {
        key: '3',
        style: { minHeight:'420px',backgroundColor:'rgba(38, 212, 177, 0.8)'},
        src: 'http://82.156.100.210/hzs/media/sxhzs/topic1.png',
        title: '全省农产品销售大数据',
        details: '市场供需、价格监测',
        path: '/discipline'
    },
    {
        key: '4',
        style: { minHeight:'420px',backgroundColor:'rgba(248, 115, 123, 0.8)'},
        src: 'http://82.156.100.210/hzs/media/sxhzs/topic_3.png',
        title: '合作社快讯',
        details: '农经快讯、要闻推送',
        path: '/discipline'
      },
]

export const subBanners = [
    {
      xk: '林学学科',
      img: '6',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林学学科',
      img: '7',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林学学科',
      img: '8',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林学学科',
      img: '9',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林学学科',
      img: '10',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林业工程学科',
      img: '11',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林业工程学科',
      img: '12',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林业工程学科',
      img: '13',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
    {
      xk: '林业工程学科',
      img: '14',
      text:'这里是关于推荐信息的相关内容描述,这里是关于推荐信息的相关内容描述,'
    },
]
