import React from 'react'
import {Button, Card,Upload,message,notification} from 'antd';
import { InboxOutlined  } from '@ant-design/icons';
import {reqUploadFile} from '../../../../assets/api';
import FileSaver from 'file-saver';
import './index.less'

const { Dragger } = Upload;

export default function Import() {

    const openNotificationWithIcon = (type,msg) => {
        notification[type]({
          message: '提示',
          description:msg,
          duration:null
        });
    };

    const postUploadFile = async (data) => {
        const result = await reqUploadFile(data);
        if (result.data.success) {
            openNotificationWithIcon('success',result.data.message);
        } else {
            openNotificationWithIcon('error',result.data.message);
        }
    };

    const AttachmentChange = (file)=>{
        let arr = '.xlsx'
        const isAlowSuffix = arr.includes(file.file.name.split('.')[1])
        const isLt2M = file.file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
            message.error('文件大小不能超过20MB!');
        }else if(!isAlowSuffix){
            message.error('文件格式错误!');
        }else{
            const formData = new FormData();
            formData.append('file',file.file);
            postUploadFile(formData)
        }
    }

    const uploadProps = {
        onRemove: file => {
        },
        accept: ".xlsx",
        customRequest:AttachmentChange,
        multiple: false,
        fileList:[]
    };  

    const download = (
        <Button 
            onClick={
                ()=>FileSaver.saveAs('http://82.156.100.210/hzs/media/files/demo.xlsx','模板.xlsx')
            }
        >
            模板下载
        </Button>
    )

    return (
        <Card title="文件导入" extra={download} className="import">
            <Dragger {...uploadProps}>
                <p className="ant-upload-drag-icon">
                <InboxOutlined />
                </p>
                <p className="ant-upload-text">单击或拖动文件到此区域以上传文件</p>
                <p className="ant-upload-hint">
                    支持.xlsx文件上传
                </p>
            </Dragger>
            {/* <Upload 
                {...uploadProps}
                accept="application/x-www-form-urlencoded"
            >
                <Button type="primary" block size="large" icon={<UploadOutlined />}>上传文件</Button>
            </Upload> */}
        </Card>
    )
}
