import React from 'react';

export default function WechartsLogin(props) {

    return (
        <div id="loginmodal">
            <h1>微信登录</h1>
            <img src="https://open.weixin.qq.com/connect/qrcode/081XvDqs03va000R" alt="wx"/>
                <div className="memo">
                    adfadsfasdfasdfasdfadsfadsf
                </div>
        </div>
    )
}
