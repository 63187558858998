import React,{useState} from 'react';
import { Form,Button,Input,Select,Card} from 'antd';


export default function SearchBox(props) {

    const [form] = Form.useForm();
    const {Option} = Select;

    const [typeOption,setTypeOption] = useState();
    const onFinish = values => {
        const a = ['种植业','畜牧业','服务业','渔业','农林特','农产品加工']
        props.setSearchObj({
            ...props.searchObj,
            page:1,
            name__icontains:values.filtername === 'name__icontains'?values.value:'',
            legal_person__icontains:values.filtername === 'legal_person__icontains'?values.value:'',
            create_user__name__icontains:values.filtername === 'create_user__name__icontains'?values.value:'',
            phonenumber__icontains:values.filtername === 'phonenumber__icontains'?values.value:'',
            items__menu2__menu1__name:values.filtername === 'production_project'?(a.indexOf(values.value)!==-1)?values.value:'':'',
            items__name:values.filtername === 'production_project'?(a.indexOf(values.value)!==-1)?'':values.value:'',
        })
        props.setFlag(!props.flag)
    };

    const sousuo = (v) => {
        setTypeOption(v)
    }

    const onReset = () => {
        props.setSearchObj({
            name__icontains:'',
            legal_person__icontains:'',
            create_user__name__icontains:'',
            phonenumber__icontains:'',
            production_project:''
    })
        props.setFlag(!props.flag)
        props.setResetFlag(!props.resetFlag)
        props.setGroupObj({city__in:"",district:"", certification__in:"", kind__in:""})
        form.resetFields()
    }

    const caseTypeOption = (t) => {
        switch (t) {
            case 'name__icontains':
                return(
                    <Input placeholder='请输入合作社名称' allowClear></Input>
                )
            case 'legal_person__icontains':
                return(
                    <Input placeholder='请输入法人姓名' allowClear></Input>
                )
            case 'create_user__name__icontains':
                return(
                    <Input placeholder='请输入代理人姓名' allowClear></Input>
                )
            case 'phonenumber__icontains':
                return(
                    <Input placeholder='请输入代理人手机号码' allowClear></Input>
                )
            case 'production_project':
                return(
                    <Input placeholder='请输入生产经营项目' allowClear></Input>
                )
            default:
                return(<Input ></Input>)
        }
    }

    return (
        <Form onFinish={onFinish} form={form} className="zkcyCard">
            <Card
                title={'关键词检索'} 
                size={'small'}
                extra={
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginLeft:'20px'}} size={'small'}>
                            提交
                        </Button>&nbsp;&nbsp;
                        <Button danger  onClick={onReset} size={'small'}>
                            重置
                        </Button>
                    </Form.Item>
                }
            >
                <Form.Item style={{flex:1}}>
                    <Input.Group compact style={{display:'flex'}}>
                        <Form.Item
                            name='filtername'
                            noStyle
                            rules={[{ required: true, message: '搜索选项不能为空！' }]}
                        >
                            <Select placeholder="搜索选项" onChange={sousuo} > 
                                <Option value="name__icontains" >合作社</Option>
                                <Option value="legal_person__icontains" >法人</Option>
                                {/* <Option value="create_user__name__icontains" >代理人</Option> */}
                                <Option value="phonenumber__icontains">手机号码</Option>
                                <Option value="production_project">经营项目</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name='value'
                            noStyle
                            rules={[{ required: true, message: '搜索内容不能为空!' }]}
                        >
                            {caseTypeOption(typeOption)}
                        </Form.Item>
                    </Input.Group>
                </Form.Item>
            </Card>
        </Form>
    );
}