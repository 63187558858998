import React,{useState} from 'react';
import {Descriptions,Button,Space,Select,Divider,Input,message} from 'antd';
import {formatTime} from '../../../../assets/zkcyFunc';
import {reqUserUpdata} from '../../../../assets/api';

const { Option } = Select;

let index = 0;

const initState = {
    items: ['如需修改数据，请重新提交修改', '合作社不支持申请管理员', '请备注管理员所在单位和职位', '拒绝申请理由四', '拒绝申请理由五' ],
    name: '',
  };

export default function HandleApply(props) {

    const postUserUpdate = async (data) => {
            const result = await reqUserUpdata(data)
            if (result.data.success) {
                message.success('更新成功')
                props.setFlag(!props.flag)
            } else {
                message.error('更新失败') 
        }
    }

    const [state,setState] = useState(initState);
    const [denyResean,setDenyResean] = useState('');
    const { items, name } = state;

    const onNameChange = event => {
        // console.log(event.target.value);
        setState({...state,
          name: event.target.value,
        });
      };
    
    const  addItem = () => {
        // console.log('addItem');
        setState({
          items: [...items, name || `New item ${index++}`],
          name: '',
        });
      };

    const handleChange = (v) => {
        setDenyResean(v);
    }

    const agreeAplly = () => {
        let show_message=
            `${formatTime(new Date())} 
            [${props.currentUserRoleName}/${props.currentUser}]
            同意了[${props.rowuser.roletitle}/${props.rowuser.name}]
            关于[${props.modalTitle}]的请求`
        const dealObj =(role)=>{
            if(role===4){
                return {id:props.rowuser.id,edit_state:1,role_apply:1,show_message:show_message}
            }else{
                return {id:props.rowuser.id,edit_state:2,role_apply:1,role_id:5-props.rowuser.role_apply,show_message:show_message}
            }
        } 
        // console.log(dealObj(props.rowuser.role_apply));
        postUserUpdate(dealObj(props.rowuser.role_apply))
        props.setShowModal(false)
    }
    
    const denyApply = () => {
        let show_message=
            `${formatTime(new Date())} 
            [${props.currentUserRoleName}/${props.currentUser}]
            拒绝了[${props.rowuser.roletitle}/${props.rowuser.name}]
            关于[${props.modalTitle}]的请求 
            [原因：${denyResean}]`
        const dealObj =(role)=>{
            return {id:props.rowuser.id,edit_state:2,role_apply:1,show_message:show_message}
        } 
        // console.log(dealObj(props.rowuser.role_apply));
        postUserUpdate(dealObj(props.rowuser.role_apply))
        props.setShowModal(false)
    }

    return (
        <div style={{padding:'16px 24px'}}>
            <Descriptions column={1} bordered size={'small'}>
                <Descriptions.Item label="用户名" >{props.rowuser.name}</Descriptions.Item>
                <Descriptions.Item label="手机号码">{props.rowuser.phonenumber}</Descriptions.Item>
                <Descriptions.Item label="权限类别">
                    [{props.rowuser.province}/{props.rowuser.city}/{props.rowuser.district}]&nbsp;
                    <span style={{color:'#1890ff'}}>[{props.rowuser.roletitle}]</span> 
                </Descriptions.Item>
                {
                    props.rowuser.role_id === 1
                    ? <Descriptions.Item label="合作社">{props.rowuser.org_name}</Descriptions.Item>
                    :null
                }
                <Descriptions.Item label="职位">{props.rowuser.position}</Descriptions.Item>
                <Descriptions.Item label="工作单位">{props.rowuser.units}</Descriptions.Item>
                <Descriptions.Item 
                    label="申请内容" 
                    style={{color:props.rowuser.role_apply !== 1?'red':'#888'}}
                >
                    [2020-12-01 13:23:43] 申请内容 ..... 
                </Descriptions.Item>
            </Descriptions>
            {
                props.rowuser.role_apply === 1
                ?null
                :<div style={{height:48,padding:'16px 0'}}>
                    <Select
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="请在列表中选择拒绝原因或自定义添加"
                        defaultValue={denyResean}
                        onChange={handleChange}
                        dropdownRender={menu => (
                        <div>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                            <Input style={{ flex: 'auto' }} value={name} onChange={onNameChange} />
                            <div
                                style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                                onClick={addItem}
                            >
                                添加原因
                            </div>
                            </div>
                        </div>
                        )}
                    >
                        {items.map(item => (
                        <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                </div>
            }
            

            <div style={{display:'flex',justifyContent:'flex-end',alignItems:'center',height:48}}>
                {
                    props.rowuser.role_apply === 1
                    ?<div>
                        <Button type={'default'} onClick={()=>props.setShowModal(false)}>关闭</Button>
                    </div>
                    :<Space>
                        <Button type={'primary'} disabled={denyResean} onClick={agreeAplly}>同意</Button>
                        <Button type={'primary'} danger={true} disabled={!denyResean} onClick={denyApply}>拒绝</Button>
                    </Space>
                }
            </div>

        </div>
    )
}
