import React from 'react';

import {
    UserOutlined,
    SolutionOutlined,
    UserAddOutlined,
    ReconciliationOutlined,
    // VerifiedOutlined,
    // GlobalOutlined,
    ImportOutlined,
    SettingOutlined,
  } from '@ant-design/icons';

import Static from '../../page/main/manage/static';
import Analysis from '../../page/main/manage/analysis';
import Verify from '../../page/main/manage/verify';
import Import from '../../page/main/manage/import';
import Report from '../../page/main/manage/report';
import Account from '../../page/main/setting/account';
import Role from '../../page/main/setting/role';
import Service from '../../page/main/setting/service';


const menuList = [
    {
        title: '数据管理',
        key: '/main/manage',
        icon: <UserOutlined/>,
        children: [
            {
                title: '数据查询',
                key: '/main/manage/static',
                icon: <SolutionOutlined/>,
                component: Static,
            },
            {
                title: '统计分析',
                key: '/main/manage/analysis',
                icon: <UserAddOutlined/>,
                component: Analysis,
            },
            {
                title: '审核申请',
                key: '/main/manage/verify',
                icon: <ReconciliationOutlined/>,
                component: Verify,
            },
            {
                title: '生成报告',
                key: '/main/manage/report',
                icon: <ReconciliationOutlined/>,
                component: Report,
            },
            {
                title: '批量导入',
                key: '/main/manage/import',
                icon: <ImportOutlined />,
                component: Import,
            },
        ]
    },
    {
        title: '系统管理',
        key: '/main/setting',
        icon: <SettingOutlined/>,
        children: [
            {
                title: '用户账号',
                key: '/main/setting/account',
                icon: <SolutionOutlined/>,
                component: Account
            },
            {
                title: '角色权限',
                key: '/main/setting/role',
                icon: <SolutionOutlined/>,
                component: Role
            },
            {
                title: '服务管理',
                key: '/main/setting/service',
                icon: <SolutionOutlined/>,
                component: Service,
            },
        ]
    },
]

export default menuList;
