import React, { useEffect, useState } from "react";
import { Card, Button, Table, Tag, message,Radio,Cascader,Row,Col,Tooltip } from "antd";
import { reqUserList, reqRoleList } from "../../../../assets/api";
import Modal from "antd/lib/modal/Modal";
import {localSet} from '../../../../assets/zkcyFunc';
import HandleApply from "./handleApply";
import {MessageOutlined} from '@ant-design/icons';
import SearchUser from '../../setting/account/SearchUser';
import chinaAddress from '../../../../assets/data/chinaAddress';

// 设置陕西省地址信息
const shannxi=chinaAddress.filter((item)=>item.value==='陕西省')[0].children;




const handleRoleFilter = (data) => {
  switch (data.role_id) {
    case 4://省管理员
      return {
        role_id__lte:3,//市管理员
        available_state:1,//在用用户
      }
    case 3://市管理员
      return {
        role_id__lte:2, //区县管理员
        available_state:1, //在用用户
      }
    case 2://区县管理员
      return {
        role_id:1, //普通用户
        available_state:1, //在用用户
      }
    default:
      return {available_state:1,}
  }
}

const applyObj = [
  {color:"#87d068",title:'正常'},
  {color:"#1890ff",title:'申请市管理员'},
  {color:"#13c2c2",title:'申请区县管理员'},
  {color:"#fa8c16",title:'修改合作社信息'},
]

export default function Verify() {

  // 通过登录用户角色，确定获取用户列表过滤条件 [roleFilterObj]
  const currentUser = localSet.getUser();
  const roleFilterObj = handleRoleFilter(currentUser)

  const [objUserList, setObjUserList] = useState({ page: 1, size: 20});
  const [data, setdata] = useState([]);
  const [total, settotal] = useState(0);
  const [rowuser, setrowuser] = useState();
  const [objRoles, setObjRoles] = useState({});
  const [flag,setFlag] = useState(true)
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [zoneFilterObj, setZoneFilterObj] = useState({city:currentUser.city,district:currentUser.district});
  const [roleApplyFilterObj, setRoleApplyFilterObj] = useState({role_apply:'',role_apply__gte:'2'});
  const [searchObj, setSearchObj] = useState({name__icontains:'',phonenumber__icontains:''});


  const handleApply = (title) => {
    // console.log(rowuser);
    setModalTitle(title)
    setShowModal(true)
  }

  const columns = [
    {
      title: "序号",
      dataIndex: "id",
      fixed:'left',
      width:'48px',
      // render:(text,record,index)=>(props.pageObj.page-1)*props.pageObj.size+index+1
  },
    {
      title: "用户名",
      dataIndex: "name",
    },
    {
      title: "手机号码",
      render: (record) => {
        if (!record.phonenumber) {
          return <span>未绑定</span>;
        } else {
          return record.phonenumber;
        }
      },
    },
    {
      title: "所在地",
      render: (record) => {
        return( 
          <div>
            <span>{record.province}</span>
            {record.city?<span>/{record.city}</span>:null}
            {record.district?<span>/{record.district}</span>:null}
          </div>
        )}
    },
    {
      title: "关联合作社",
      dataIndex: "hzsId",
    },
    {
      title: "申请时间",
      dataIndex: "update_time",
    },
    {
      title: "权限",
      render: (record) => {
        return objRoles[record.role_id.toString()];
      },
    },
    {
      title: "申请记录",
      render: (record) => {
        return <Tooltip title={record.show_message}>
          <span>{record.show_message?<MessageOutlined style={{color:'#f5222d'}}/>:null}</span>
        </Tooltip>
      }
    },
    {
      title: "状态",
      render: (record) => {
        return <Tag 
                  color={applyObj[record.role_apply-1].color} 
                  onClick={()=>handleApply(applyObj[record.role_apply-1].title)}
               >
                  {applyObj[record.role_apply-1].title}
               </Tag>;
      },
      width:'120px',
      fixed:'right'
    },
  ];



  const getUserList = async (reqData) => {
    if (currentUser.role_id<=3){
      reqData['city'] = currentUser.city
    }
    if (currentUser.role_id==2){
      reqData['district'] = currentUser.district
    }
    console.log(currentUser);
    console.log(111,reqData);

    const result = await reqUserList(reqData);
    if (result.data.success) {
      const user = result.data.obj;
      setdata(user);
      settotal(result.data.count);
    } else {
      message.error("请求机构数据失败！");
    }
  };

  const transRolesAsObj = (data) => {
    if (data) {
      let result = {};
      data.forEach((item) => {
        Object.assign(result, JSON.parse(`{"${item.id}":"${item.name}"}`));
      });
      return result;
    }
  };

  const getRoleList = async () => {
    const result = await reqRoleList();
    if (result.data.success) {
      // setroles(result.data.obj);
      setObjRoles(transRolesAsObj(result.data.obj));
    } else {
      message.error("请求机构数据失败！");
    }
  };

  useEffect(() => {
    // console.log(zoneFilterObj);
    getRoleList();
    getUserList(Object.assign(
        objUserList,
        {...roleFilterObj},
        {...zoneFilterObj},
        {...roleApplyFilterObj},
        {...searchObj}
      ));
    // eslint-disable-next-line
  }, [objUserList.page,objUserList.size,flag]);

  const radioGroupChange = e => {
    // console.log('radioGroupChange',e.target.value);
    switch (e.target.value) {
      case "a":
        setRoleApplyFilterObj({role_apply:'',role_apply__gte:2,role_apply__in:''})
        break;
      case "b":
        setRoleApplyFilterObj({role_apply:'',role_apply__gte:'',role_apply__in:'2,3'})
        break;
      case "c":
        setRoleApplyFilterObj({role_apply:4,role_apply__gte:'',role_apply__in:''})
        break;
      default:
        break;
    }
    setFlag(!flag)
  }

  const cascaderChange = v => {
    if(v.length){
      setZoneFilterObj({city:v[0],district:v[1]})
    }else{
      setZoneFilterObj({city:'',district:''})
    }
    setFlag(!flag)
  }

  const title = (
    <Row gutter={16} justify={'space-between'}>
      <Col md={24} lg={12}>
        <SearchUser 
          searchObj={searchObj}
          setSearchObj={setSearchObj}
          flag={flag}
          setFlag={setFlag}
          // setObjUserList={setObjUserList}
          // objUserList={objUserList}
        />
      </Col>
      <Col md={24} lg={12}style={{display:'flex',justifyContent:'flex-end'}}>
        <div>
          <Cascader
            options={shannxi}
            placeholder="请选择市县过滤"
            onChange={cascaderChange}
            changeOnSelect
          />
        </div>
        &nbsp;&nbsp;
        <Radio.Group defaultValue="a" buttonStyle="solid" onChange={radioGroupChange}>
          <Radio.Button value="a">全部申请</Radio.Button>
          <Radio.Button value="b">管理员</Radio.Button>
          <Radio.Button value="c">用户</Radio.Button>
        </Radio.Group>
        &nbsp;&nbsp;
        <Button >批量操作</Button>
      </Col>
    </Row>
  );

  const pageonChange = (page, pageSize) => {
    setObjUserList({ ...objUserList,page:page,size:pageSize });
  };

  const showTotal = () => {
    return "总数:" + total + "条";
  };

  const onRow = (val) => {
    return {
      onClick: (event) => {
        setrowuser({...val,roletitle:objRoles[val.role_id.toString()]});
      },
    };
  };

  const rowSelection = {
    type: "checkbox",
  };

  return (
    <div>
      <div className="site-card-border-less-wrapper">
        <Card title={title} bordered={false}>
          <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            bordered={true}
            onRow={onRow}
            size={'small'}
            scroll={{ x: 1200}}
            pagination={{
              defaultPageSize: objUserList.size,
              current:objUserList.page,
              pageSizeOptions: ["10", "20", "50"],
              showSizeChanger: true,
              total: total,
              onChange: pageonChange,
              onShowSizeChange: pageonChange,
              showTotal: showTotal,
            }}
          />
          <Modal
            title={modalTitle}
            visible={showModal}
            footer={null}
            centered={true}
            width={480}
            destroyOnClose={true}
            onCancel={() => setShowModal(false)}
          >
            <HandleApply 
              modalTitle={modalTitle}
              currentUser={currentUser.name}
              currentUserRoleName={objRoles[currentUser.role_id.toString()]}
              rowuser={rowuser} 
              setShowModal={setShowModal}
              flag={flag}
              setFlag={setFlag} 
            />
          </Modal>
        </Card>
      </div>
    </div>
  );
}
