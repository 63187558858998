import React from 'react';
import './LinkButton.less';

const LinkButton = (props) => {
    return (
            <button {...props} className="link-button" >{props.children}</button>
        );
}

export default LinkButton;


