import React from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined} from '@ant-design/icons';
import {HoxLogin} from '../../assets/hox';
import {localSet} from '../../assets/zkcyFunc.jsx'
import {reqLogin} from '../../assets/api.jsx';
import md5 from 'md5';

export default function UserNameLogin(props) {
    
    const {setLoginUser} = HoxLogin();

    const getLogin = async (data) => {
        const result = await reqLogin(data);
        if (result.data.success){
            const dataObj = result.data.obj;
            let currentStamp = new Date().getTime()
            localSet.saveUser({...dataObj,last_login:currentStamp})
            setLoginUser({...dataObj,last_login:currentStamp})
        } else {
            message.error('用户名或密码错误！')
        }
    }

    const onFinish = values => {
        const {username} = values;
        const password = md5(values.password)
        // console.log({phonenumber:username,password:password});
        getLogin({phonenumber:username,password:password})
    }
    
    return (
        <div id="loginmodal">
            <h1>登录验证</h1>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="username"
                    rules={[
                    {
                        required: true,
                        message: '用户名不能为空，请输入！',
                    },
                    ]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入用户名称" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: '密码不能为空，请输入！',
                    },
                    ]}
                >
                    <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="请输入密码"
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>记住密码</Checkbox>
                    </Form.Item>

                    <span className="zkcy_linkbutton" 
                    onClick={()=>message.warning('请联系管理员重置！')}
                    style={{float:'right',cursor:'pointer'}}>忘记密码</span>
                </Form.Item>

                <Form.Item>
                    <Button style={{width:'100%'}} type="primary" htmlType="submit" className="login-form-button">
                     用户登录
                    </Button>
                </Form.Item>
                {/* <div className="footer">
                    <span className="zkcy_linkbutton" onClick={()=>{setModalIndex(2)}}>
                        <WeiboSquareOutlined style={{fontSize:'18px',color:'lightgreen'}} />
                        &nbsp;微信登录
                    </span>
                    <span className="zkcy_linkbutton">注册用户</span>
                </div> */}
            </Form>
        </div>
    )
}
