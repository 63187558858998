import React, { useState, useEffect } from "react";
import { Table, Card, Button, message } from "antd";
import RoleModal from "./roleModal";
import { reqRoleList } from "../../../../assets/api";
import {localSet} from "../../../../assets/zkcyFunc";

function Role() {
  const [mvisible, setMvisible] = useState(false);
  const [modalTitle, setmodalTitle] = useState("创建角色");
  const [pagedata, setpagedata] = useState({ page: 1, size: 10 });
  const [total, settotal] = useState(0);
  const [data, setdata] = useState([]);
  const [role, setrole] = useState({});
  const [userId, setUserId] = useState();
  const columns = [
    {
      title: "角色名称",
      dataIndex: "name",
      width:'96px',
      fixed:'left'
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
    },
    {
      title: "修改时间",
      dataIndex: "update_time",
    },
    {
      title: "操作人",
      dataIndex: "update_user_name",
    },
    {
      title: "操作",
      // width:'120px',
      fixed:'right',
      width:'96px',
      render: () => {
        return (
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div
              onClick={() => {
                setMvisible(true);
                setmodalTitle("设置角色权限");
              }}
              style={{ color: "#1890FF", cursor: "pointer" }}
            >
              设置
            </div>
            <div
              onClick={() => {
                setMvisible(true);
                setmodalTitle("删除角色");
              }}
              style={{ color: "red", cursor: "pointer" }}
            >
              删除
            </div>
          </div>
        );
      },
    },
  ];

  const getRoleList = async (data) => {
    const result = await reqRoleList(data);
    if (result.data.success) {
      const roles = result.data.obj;
      setdata(roles);
      settotal(result.data.count);
    } else {
      message.error("请求角色列表失败");
    }
  };

  const showTotal = () => {
    return "总数:" + total + "条";
  };

  const pageonChange = (page, pageSize) => {
    let nowsize = pageSize || pagedata.size;
    let nowpage = page || pagedata.page;
    setpagedata({ page: nowpage, size: nowsize });
  };

  const onRow = (val) => {
    return {
      onClick: (event) => {
        setrole(val);
      },
    };
  };

  const title = (
    <span>
      <Button
        type="primary"
        onClick={() => {
          setMvisible(true);
          setmodalTitle("创建角色");
        }}
        style={{ marginRight: "10px" }}
      >
        创建角色
      </Button>
    </span>
  );

  useEffect(() => {
    getRoleList({ page: pagedata.page, size: pagedata.size });
    setUserId(localSet.getUser().id);
  }, [pagedata.page, pagedata.size]);

  return (
    <div className="site-card-border-less-wrapper">
      <Card title={title} bordered={false}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data}
          onRow={onRow}
          bordered={true}
          size={"small"}
          scroll={{ x: 1200}}
          pagination={{
            defaultPageSize: pagedata.size,
            pageSizeOptions: [10,20,50],
            showSizeChanger: true,
            total: total,
            onChange: pageonChange,
            onShowSizeChange: pageonChange,
            showTotal: showTotal,
          }}
        />
        <RoleModal
          pagedata={pagedata}
          getRoleList={getRoleList}
          mvisible={mvisible}
          role={role}
          setrole={setrole}
          setMvisible={setMvisible}
          title={modalTitle}
          destroyOnClose={true}
          userId={userId}
        />
      </Card>
    </div>
  );
}

export default Role;
