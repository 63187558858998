import React,{useState,useEffect} from 'react';
import {Row,Col,message} from 'antd';
import ClusterBox from './left/ClusterBox';
import SearchBox from './left/SearchBox';
// import SearchTags from './left/SearchTags';
import StaticChart from './right/StaticChart';
import HzsTable from './right/HzsTable';
import {localSet} from '../../../../assets/zkcyFunc';

import {reqHzsList,reqGroupAll,reqExcelList} from '../../../../assets/api';

const setRoleObj = (user) => {
    switch (user.role_id) {
        case 5:
            return {province:'陕西省',step_state__gte:4,city:'',district:''}
        case 4:
            return {province:'陕西省',step_state__gte:4,city:'',district:''}
        case 3:
            return {province:'陕西省',step_state__gte:4,city:user.city,district:''}
        case 2:
            return {province:'陕西省',step_state__gte:4,city:user.city,district:user.district}
        default:
            break;
    }
}


export default function Static() {
    // 读取当前登录用户信息
    const currentUser = localSet.getUser();
    // 按当前登陆用户权限设置检索过滤条件
    const roleObj = setRoleObj(currentUser);

    const [flag,setFlag] = useState(true);
    const [searchObj,setSearchObj] = useState({});
    const [pageObj,setPageObj] = useState({page:1,size:10})
    const [total,setTotal] = useState(0)
    const [hzsData,setHzsData] = useState([])
    const [groupData,setGroupData] = useState([])
    const [groupObj,setGroupObj] = useState({city__in:"",district:"", certification__in:"", kind__in:""})
    const [resetFlag,setResetFlag] = useState(true)
    const [excelUrl,setExcelUrl] = useState();

    const getHzsList = async (reqData) => {
        const result = await reqHzsList(reqData);
        if (result.data.success) {
          const user = result.data.obj;
          setHzsData(user);
          setTotal(result.data.count);
        } else {
          message.error("请求合作社数据列表失败！");
        }
    };

    const getGroupAll =async (reqData) => {
        if(groupObj.city__in){
            reqData['city__in'] = groupObj.city__in
        }
        if(groupObj.district__in){
            reqData['district__in'] = groupObj.district__in
        }
        if(groupObj.level){
            reqData['level__in'] = groupObj.level
        }
        const result = await reqGroupAll(reqData);
        if (result.data.success) {
          const user = result.data.obj;
          setGroupData(user);
        } else {
          message.error("请求聚类数据列表失败！");
        }        
    }

    const getExcelList = async (reqData) => {
        const result = await reqExcelList(reqData);
        if (result.status===200) {
            setExcelUrl(result.data)
        }else {
          message.error("请求合作社数据列表失败！");
        }
    };
    useEffect(()=>{
        getHzsList(Object.assign(
                pageObj,
                {...searchObj},
                {...groupObj},
                {...roleObj}
            ))
        getExcelList(Object.assign(
                pageObj,
                {...searchObj},
                {...groupObj},
                {...roleObj}
            ))
        // eslint-disable-next-line
    },[flag])
    useEffect(()=>{
        getGroupAll({id:currentUser.id})  
        // eslint-disable-next-line      
    },[resetFlag])

    return (
        <div>
            <Row gutter={16}>
                <Col  lg={6} md={8} sm={24} xs={24} >
                    <div style={{backgroundColor:'white',padding:16}}>
                        <SearchBox
                            flag={flag}
                            setFlag={setFlag}
                            searchObj={searchObj}
                            setSearchObj={setSearchObj}
                            resetFlag={resetFlag}
                            setResetFlag={setResetFlag}
                            setGroupObj={setGroupObj}
                        />
                        {/* <SearchTags/> */}
                        <ClusterBox
                            groupData={groupData}
                            setGroupObj={setGroupObj}
                            flag={flag}
                            setFlag={setFlag}
                            resetFlag={resetFlag}
                            setResetFlag={setResetFlag}
                        />
                    </div>
                </Col>
                <Col  lg={18} md={16} sm={24} xs={24}>
                    <div style={{backgroundColor:'white',padding:16}}>
                        <StaticChart/>
                        <HzsTable
                            flag={flag}
                            setFlag={setFlag}
                            pageObj={pageObj}
                            setPageObj={setPageObj}
                            total={total} 
                            hzsData={hzsData}   
                            excelUrl={excelUrl}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
