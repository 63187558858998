import React from 'react';
import { Link } from 'react-router-dom';
import './index.less';
import '../zkcy.less';
import { Carousel,Button } from 'antd';
import {bannerDetails} from '../../assets/data/configData';
import Footer from './footer';
import LoginSection from './loginSection';


export default function Home (props) {

    const banner_inner = (
        bannerDetails.map((item,index) => {return(
            <div key={index} >
                <div className='lbt' style={{ backgroundColor:`${item.style.backgroundColor}`}}>
                    <div className='left' >
                        <img  src={item.src} alt="" />
                    </div>
                    <div className='right'>
                        <div className='title'>{item.title}</div>
                        <div className='details'>{item.details}</div>
                        <div className='buttons'>
                            {
                                item.path?
                                <Link to={item.path}>
                                    <Button style = {{textAlign:'center'}} type = "primary" >点击进入</Button>
                                </Link>
                                :null
                            }
                        </div>
                    </div>
                </div>
            </div>
        )})
    )

    return (
        <div className='home zkcy'>

            <div className="header center">
                <img 
                    className="img_left"
                    src="http://82.156.100.210/hzs/media/sxhzs/图标文字logo.png"  
                    alt="img_left"
                />
                <LoginSection sub={{...props}} />
            </div>

            <div className="content bg">
                <div className="show center">
                    <Carousel  effect="fade"   >
                        {banner_inner}
                    </Carousel>
                </div>
            </div>

            <Footer></Footer>
        </div>
    )
}
