import React from 'react'

export default function Footer() {
    return (
        <div className="footer bg">
            <div className="center">
                <img 
                    style={{height:'20px',width:'18px'}} 
                    src="http://82.156.100.210/hzs/media/sxhzs/gongan.png"  
                    alt="gonganlogo"
                />
                &nbsp;&nbsp;陕ICP备19008641号
            </div>
        </div>
        )
}
