import React,{useState} from 'react';
import {Card,Table,Button, Tag,message,Modal} from 'antd';
import HzsDrawer from './HzsDrawer';
import {reqDeleteHzs} from '../../../../../assets/api'
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

export default function HzsTable(props) {

    const [selectKeys,setSelectKeys] = useState([]);

    const postDeleteHzs =async (reqData) => {
        const result = await reqDeleteHzs(reqData);
        if (result.data.success) {
            props.setFlag(!props.flag)
            message.success(result.data.message);
        } else {
          message.error(result.data.message);
        }        
    }

    const deleteHzs=()=> {
        if(selectKeys.length){
            confirm({
              title: '您确定要删除该合作社吗',
              icon: <ExclamationCircleOutlined />,
              cancelText:'取消',
              okText:'确定',
              onOk() {
                postDeleteHzs({org_ids:selectKeys.toString()})
              },
              onCancel() {},
            });
        }else{
            message.error('请选择合作社')
        }
      }

    const onSelectChange = selectedRowKeys => {
        setSelectKeys(selectedRowKeys)
    };

    const rowSelection = {
        selectedRowKeys:selectKeys,
        onChange: onSelectChange,
    };

    const onRow = (val) => {
        return {
            onClick: (event) => {
                setRowData(val)
            },
        };
    };

    const columns = [
        {
            title: "序号",
            fixed:'left',
            width:'48px',
            render:(text,record,index)=>(props.pageObj.page-1)*props.pageObj.size+index+1
        },
        {
            title: "合作社名称",
            dataIndex: "name",
        },
        {
            title: "种类",
            render: (record) => {
            return( 
                record.kind===1?<span>合作社</span>:<span>联合社</span>
            )}
        },
        {
            title: "等级",
            dataIndex: "level",
        },
        {
            title: "法人",
            dataIndex: "legal_person",
        },
        {
            title: "所在地",
            render: (record) => {
            return( 
                <div>
                <span>{record.province}</span>
                {record.city?<span>/{record.city}</span>:null}
                {record.district?<span>/{record.district}</span>:null}
                </div>
            )}
        },
        {
            title: "手机号码",
            render: (record) => {
            if (!record.phonenumber) {
                return <span>未绑定</span>;
            } else {
                return record.phonenumber;
            }
            },
        },
        {
            title: "申报人",
            dataIndex: "create_user_name",
        },
        {
            title: "申报日期",
            dataIndex: "create_time",
        },
        {
            title: "操作",
            render: () => {
                return <Tag color={'purple'} onClick={()=>setIshow(true)}>编辑+详情</Tag>
            },
            fixed:'right',
            width:'104px'
        },
    ]

    const [isShow,setIshow] = useState(false)
    const [rowData,setRowData] = useState()

    const pageonChange = (page, pageSize) => {
        props.setPageObj({ page:page,size:pageSize });
        props.setFlag(!props.flag);
    };
    
    const showTotal = () => {
        return "共计 " + props.total + " 用户";
    };

    return (
        <div>
            <HzsDrawer
                isShow={isShow}
                setIshow={setIshow}
                rowData={rowData}
                flag={props.flag}
                setFlag={props.setFlag}
            />
            <Card 
                title={'合作社列表'} 
                size={'small'}
                extra={
                    <div>
                        <Button size={'small'} href={props.excelUrl} >导出</Button>
                        <Button size={'small'} onClick={deleteHzs} style={{marginLeft:'16px'}} danger >删除</Button>
                    </div>
                } 
            >
                <Table
                    rowKey="id"
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={props.hzsData}
                    bordered={true}
                    onRow={onRow}
                    size={'small'}
                    scroll={{ x: 1200}}
                    pagination={{
                        defaultPageSize: props.pageObj.size,
                        current:props.pageObj.page,
                        pageSizeOptions: [10,20,50],
                        showSizeChanger: true,
                        total: props.total,
                        onChange: pageonChange,
                        onShowSizeChange: pageonChange,
                        showTotal: showTotal,
                    }}
                />
            </Card>
        </div>
    )
}
