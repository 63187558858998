import store from 'store';
import menuList from '../assets/data/menuConfig.js'
const USER_KEY = 'user_key'

export const localSet = {

    // 在本地存储用户信息
    saveUser (user){
        store.set(USER_KEY,user)
    },

    // 获取本次存储的用户信息
    getUser (){
        return store.get(USER_KEY) || {}
    },

    // 删除存储在本地的用户信息
    removeUser (){
        store.remove(USER_KEY)
    },
}

// 将字符串转换为时间戳
export function transStr2Stamp (str) {
    str = str.substring(0,19);    
    str = str.replace(/-/g,'/'); //必须把日期'-'转为'/'
    // let timestamp = new Date(str).getTime();
    return new Date(str).getTime();
}

 // 复杂对象或数组的深拷贝
export function deepClone(obj = {}){
    if(typeof obj !== 'object' || obj == null){ return obj } 
    
    let result; 
    if (obj instanceof Array) {
       result = [] 
     }
    else{
       result = {} 
     } 
    for(let key in obj){ 
      if(obj.hasOwnProperty(key)){ result[key] = deepClone(obj[key]) } 
     } 
   return result 
}

// 在字符串数组中查找是否包含检索字符，找到符合结果即返回 true
export const findCloudesStrInArray = (arr,str) => {
    let back = false
    arr.forEach(item =>{
      if (item.includes(str)) {
        back =true
        return
      }
    })
    return back
}

export const transRoleList = (permission) => {
  const menuListCopy = deepClone(menuList)
  let result = [];
  menuListCopy.forEach(element => {
    if (permission.indexOf(element.key) !==-1){
      result.push(element);
    } 
    else if (findCloudesStrInArray(permission,element.key)){
      let childArr = []
      element.children.forEach(child => {
        if (permission.indexOf(child.key) !==-1) {
          childArr.push(child)
        }
      })
      Object.assign(element,{children:childArr})
      result.push(element);
    }
  });
  return result
}

/**
 * 时间戳格式化日期，一般后台给的都是秒级的时间戳,我们PHP的后台给的就是秒级的，可自行 *1000修改
 * timestamp:时间戳
 * dateS:日期间隔符 默认 -
 * timeS:时分秒间隔符 默认 :
 * flag:是否携带 时分秒 默认 false
 */
export function formatTime(timestamp,separator='-',flag = true,timeS=':') {
  let str = '';
  let date = new Date(timestamp ); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + separator;
  let M = autoChange(date.getMonth() + 1) + separator; //计算机的月份是从0开始滴，需要+1
  let D = autoChange(date.getDate());
  str = Y + M + D ;
  if(flag){
    let h = autoChange(date.getHours()) + timeS;
    let m = autoChange(date.getMinutes()) + timeS;
    let s = autoChange(date.getSeconds());
      let timeStr = h + m + s ;
      str += " ";
      str += timeStr;
  }
  return `[${str}]`;
}
function autoChange(num) {
  if (num < 10) {
      return "0" + num;
  } else {
      return num;
  }
}