import React,{useState,useEffect} from 'react';
import {Collapse,Card,List,Checkbox,Tag} from 'antd';
import { isString } from 'lodash';

const { Panel } = Collapse;


export default function ClusterBox(props) {

    const [activekey,setActivekey] = useState(1);
    const [filter,setFilter] = useState()
    useEffect(()=>{
        setFilter(props.groupData)
    },[props.groupData])

    const onChange = (e) => {
        let {pkey,ckey,checked} = e.target;
        changeTag(pkey,ckey,checked);
    }
    const changeTag = (pkey,ckey,checked)=>{
        let i1=filter.findIndex((value)=>value.filtername===pkey);
        let i2=filter[i1].subitem.findIndex((value)=>value.key===ckey);
        let data = JSON.parse( JSON.stringify(filter));
        data[i1].subitem[i2].checked  = checked;
        setFilter(data);
        console.log(getFilterObj(data));
        props.setGroupObj(getFilterObj(data)) 
        props.setFlag(!props.flag)
        console.log(pkey);
        if(pkey=='city__in'){
            props.setResetFlag(!props.resetFlag)
        }

    }
    const getFilterObj = (data) => {
        if (data){
            let result={};
            data.forEach(item => {
                let valueStrList = []
                item.subitem.forEach(each => {
                    if(each.checked){
                        valueStrList.push(each.value)
                    }
                });
                Object.assign(result,JSON.parse(`{"${item.filtername}":"${valueStrList.join(',')}"}`))
            });
            return result;
        }
    }
    
    const getPanel = filter?filter.map((item,index)=>{
        let pkey=item.filtername;
        return(
            <Panel header={item.title} key={index+1} >
                <List
                    size="small"
                    dataSource={item.subitem.filter(item=>item.doc_count)}
                    pagination={{
                        defaultPageSize:10,
                        defaultCurrent:1,
                        size:'small',
                        showSizeChanger:false,
                        showLessItems:false,
                    }}
                    renderItem={(sub) => 
                        <List.Item style={{display:'flex'}}>
                            <span style={{width:20,textAlign:'left'}}>
                                <Checkbox 
                                    key={sub.key}
                                    pkey={pkey}
                                    ckey={sub.key}
                                    checked={sub.checked}
                                    onChange={onChange} 
                                />
                            </span>
                            <span style={{flex:1,paddingLeft:'4px'}}>
                                <span>{isString(sub.name)?sub.name.toUpperCase():sub.name}</span>
                                <span>{sub.gh?'('+sub.gh+')':null}</span>    
                            </span>
                            <span style={{width:32,textAlign:'right'}}>{sub.doc_count}</span>
                        </List.Item>}
                />
            </Panel>
        )   
    }):null

    const getTags = (filter)=>{
        if (filter){
            let result=[];
            filter.forEach(item => {
                if(item.subitem){
                    item.subitem.forEach(each => {
                        if(each.checked){
                            result.push(<Tag 
                                color={'cyan'}
                                key={item.key+ each.key} 
                                onClose={()=>{changeTag(item.filtername,each.key,!each.checked)}} 
                                closable style={{marginBottom:4,borderRadius:4}}>
                                    {each.name}
                                    {/* {each.gh?'('+each.gh+')':null} */}
                                </Tag>);
                        }
                    });
                }
            });
            return result;
        }
    }  




    return (
        <div>
            <div style={{padding:'8px 16px'}} >
                {getTags(filter)}
            </div>

            <Card title={'聚类项目'} size={'small'} style={{marginTop:16}}>
                <Collapse
                    defaultActiveKey={activekey}
                    style={{textAlign:'left'}}
                    onChange={(key)=>{setActivekey([key])}}
                    size={'small'}
                    bordered={true}
                    accordion={true}
                    expandIconPosition={'right'}
                    className="site-collapse-custom-collapse"
                >
                    {getPanel}
                </Collapse>   
            </Card>
        </div>

    )
}
