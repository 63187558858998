import React,{useState} from 'react';
import { Form,Button,Input,Select } from 'antd'

function SearchUser(props) {

    const [form] = Form.useForm();
    const {Option} = Select;

    const [typeOption,setTypeOption] = useState();

    const onFinish = values => {
        props.setSearchObj({
            name__icontains:values.filtername === 'name'?values.value:'',
            phonenumber__icontains:values.filtername === 'phonenumber'?values.value:''
        })
        props.setFlag(!props.flag)
    };

    const sousuo = (v) => {
        setTypeOption(v)
    }

    const onReset = () => {
        props.setSearchObj({name__icontains:'',phonenumber__icontains:''})
        props.setFlag(!props.flag)
        form.resetFields()
    }

    const caseTypeOption = (t) => {
        switch (t) {
            case 'name':
                return(
                    <Input placeholder='请输入用户姓名' allowClear></Input>
                )
            case 'phonenumber':
                return(
                    <Input placeholder='请输入用户手机号码' allowClear></Input>
                )
            default:
                return(<Input ></Input>)
        }
    }

    return (
        <div>
            <Form onFinish={onFinish} form={form}>
                <div style={{display:'flex'}}>
                    <Form.Item >
                        <Input.Group compact style={{display:'flex'}}>
                            <Form.Item
                                name='filtername'
                                noStyle
                                rules={[{ required: true, message: '搜索选项不能为空！' }]}
                            >
                                <Select placeholder="搜索选项" onChange={sousuo} > 
                                    <Option value="name" >用户名</Option>
                                    <Option value="phonenumber">手机号码</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name='value'
                                noStyle
                                rules={[{ required: true, message: '搜索内容不能为空!' }]}
                            >
                                {caseTypeOption(typeOption)}
                            </Form.Item>
                        </Input.Group>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{marginLeft:'20px'}}>
                            提交
                        </Button>&nbsp;&nbsp;
                        <Button danger  onClick={onReset}>
                            重置
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}

export default SearchUser;