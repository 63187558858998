import React,{useState} from 'react';
import './loginSection.less';
import LinkButton from '../../assets/zkcyComponts/LinkButton';
import { Modal, Menu, Dropdown,Form,Input, message } from 'antd';
import  { HoxLogin} from '../../assets/hox';
import {reqChangePassword} from '../../assets/api'
import {localSet} from '../../assets/zkcyFunc';
import {useHistory} from 'react-router-dom';
import md5 from 'md5';

export default function LoginSection (props) {

    const jump = useHistory();
    const [form] = Form.useForm();
    const user = localSet.getUser();
    const [visible,setVisible] = useState();

    const postChangePassword = async (data) => {
        const result = await reqChangePassword(data);
        if (result.data.success){
            localSet.removeUser();
            setVisible(false);
            jump.push('/login')
            message.success(result.data.Message)
        } else {
            message.error(result.data.Message)
        }
    }
    
    const {setLoginUser} = HoxLogin();

    const loginOut = () => {
        return (
            Modal.confirm({
                style:{padding:'32px'},
                width:500,
                okText: '确定',
                cancelText: '取消',
                title: '操作提示',
                content: '您确定要退出系统吗？',
                onOk:  () => {
                    localSet.removeUser();
                    setLoginUser({});
                    props.sub.history.push('/');
                }
            })
        )
    }

    const enterIn = ()=> {
        props.sub.history.push('/main')
    }

    const menu = (
        <Menu style={{width:100}}>
        <Menu.Item key="1">
            <LinkButton onClick={enterIn} >进入系统</LinkButton>
        </Menu.Item>
        <Menu.Divider key="2"/>
        <Menu.Item>
            <LinkButton onClick={()=>setVisible(true)} >修改密码</LinkButton>
        </Menu.Item>
        <Menu.Divider key="3"/>
        <Menu.Item>
            <LinkButton onClick={loginOut}>退出登录</LinkButton>
        </Menu.Item>
        </Menu>
    );

    const onOk = () => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            let obj = {
                newpassword1:md5(values.newpassword1),
                newpassword2: md5(values.newpassword2),
                oldpassword: md5(values.oldpassword),
            }
            postChangePassword(obj)
          })
          .catch((info) => {
          });
    }

    return (
        <div id="loginSection" >
            {!!user.name ? (
                <Dropdown overlay={menu}>
                    <div className='inner'>
                        <img src={`http://82.156.100.210/hzs/media/sxhzs/logo_user.png`} alt="userImg"/>
                        <span>
                            <LinkButton >{ user.name }</LinkButton>
                            {
                                user.role_name ? (
                                    <span style={{color:'#1DA57A'}}>[ { user.role_name } ]</span>
                                ) : null
                            }
                            
                        </span>
                    </div>
                </Dropdown>

            ) : (
                <div className='inner' onClick={()=>{props.sub.history.push('/login')}}>
                    <img src={`http://82.156.100.210/hzs/media/sxhzs/logo_user.png`} alt="userImg"/>
                    <span>
                        <LinkButton >登录</LinkButton>
                    </span>
                </div>
            )}
            <Modal
                title="修改密码"
                visible={visible}
                onCancel={()=>setVisible(false)}
                onOk={onOk}
                okText="确定"
                cancelText="取消"
                bodyStyle={{padding:24}}
            >
                <Form
                    form={form}
                    layout="vertical"
                    name="form_in_modal"
                >
                    <Form.Item
                        name="oldpassword"
                        label="旧密码"
                        rules={[
                            {
                                required: true,
                                message: '旧密码不能为空',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="newpassword1"
                        label="新密码"
                        rules={[
                            {
                                required:true,
                                validator:async (rule, value) => {
                                    if([null,undefined,''].includes(value)){
                                        throw new Error('新密码不能为空!');
                                    }else if(value.length<6){
                                        throw new Error('密码长度6位以上!');
                                    }
                                }
                            }
                        ]}
                        hasFeedback
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name="newpassword2"
                        label="确认密码"
                        dependencies={['newpassword1']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: '确认密码不能为空！',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('newpassword1') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('两次密码不一致!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}


