import React from 'react';
import { Form, Button, Input, Select, Switch, message,Divider,Cascader} from 'antd';
import { UserOutlined,MobileOutlined } from '@ant-design/icons';
import {reqUserUpdata} from '../../../../assets/api';
import chinaAddress from '../../../../assets/data/chinaAddress';
import {formatTime} from '../../../../assets/zkcyFunc';

const shannxi=chinaAddress.filter((item)=>item.value==='陕西省')

function UserSettingModal(props) {

    const [form] = Form.useForm();
    const user = props.rowuser
    const state = user ? (user.available_state === 1 ? true : false) : true

    const postUserUpdate = async (data) => {
        if (user.id) {
                const result = await reqUserUpdata(data)
                if (result.data.success) {
                    message.success('更新成功')
                    props.setFlag(!props.flag)
                } else {
                    message.error('更新失败') 
                }
        }
    }

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    const options = props.roles ? props.roles.map(item => (
        { label: item.name, key: item.id, value: item.id }
    )) : {}

    const onFinish = values => {
        let show_message=
            `${formatTime(new Date())} 
            [${props.currentUserRoleName}/${props.currentUser}]
            修改了[${props.rowuser.roletitle}/${props.rowuser.name}]
            的账户信息`

        const updataObj = {
            id:user.id,
            phonenumber:values.phonenumber,
            role_id:values.role,
            available_state:values.available_state?'1':'2',
            province:user.province,
            city:user.city,
            district:user.district,
            show_message:show_message            
        }
        if (values.password){
            postUserUpdate(updataObj);
        }else{
            postUserUpdate(Object.assign(updataObj,{password:'e10adc3949ba59abbe56e057f20f883e'}));
        }

    }

    const onCancel = () => {
        props.setShowModalB(false)
        form.resetFields();
    }

    function onChange(value) {
        console.log(value);
        Object.assign(user,{
            province:value[0]?value[0]:'陕西省',
            city:value[1]?value[1]:'',
            district:value[2]?value[2]:''
        })
      }

    return (
        <div>
             <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        style={{marginTop:'20px'}}
                        label="用户姓名"
                    >
                        <span style={{color:'#1DA57A'}}>&nbsp;&nbsp;<UserOutlined />&nbsp;{user.name}</span>
                        {/* <Input disabled={true} prefix={<UserOutlined />} /> */}
                    </Form.Item>
                    <Form.Item
                        label="手机号码"
                        name="phonenumber"
                        rules={[{ 
                            required: true, 
                            pattern: /^1[34578]\d{9}$/,
                            message: '手机号码格式不正确!' 
                          }]}
                        initialValue={user.phonenumber}
                    >
                        <Input prefix={<MobileOutlined />}/>
                    </Form.Item>
                    <Form.Item
                        label="所属地"
                        rules={[{ 
                            required: true, 
                            message: '所属地不能为空!' 
                          }]}
                    >
                        <Cascader
                            defaultValue={[user.province,user.city,user.district]}
                            options={shannxi}
                            onChange={onChange}
                            changeOnSelect
                        />
                    </Form.Item>

                    <Form.Item
                        label="角色"
                        name="role"
                        normalize = {(value)=>{return value}}
                        initialValue={user.role_id}
                    >
                        <Select
                            options={options} 
                        />
                    </Form.Item>

                    <Form.Item
                        label="当前状态"
                        name="available_state"
                        initialValue={state}
                        valuePropName='checked'
                    >
                        <Switch
                            checkedChildren='在用'
                            unCheckedChildren="停用" 
                        />
                    </Form.Item>
                    <Form.Item
                        label="密码"
                        name="password"
                        initialValue={true}
                        valuePropName='checked'
                    >
                        <Switch
                            checkedChildren='密码'
                            unCheckedChildren="密码已重置" 
                        />
                    </Form.Item>
                    <Divider/>

                    <Form.Item
                        labelCol={{ span: 6 }} wrapperCol={{ span: 20, offset: 2 }}
                    >
                        <div style={{ marginBottom:'20px',float:'right'}}>
                            <Button  onClick={onCancel}>
                                取消
                            </Button>&nbsp;&nbsp;
                            <Button type="primary" htmlType="submit" onClick={()=>props.setShowModalB(false)}>
                                提交
                            </Button>
                        </div>
                    </Form.Item>
                    </Form>
        </div>
    );
}

export default UserSettingModal;