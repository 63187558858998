import React, { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Modal, Form, Input, Tree, message, Button } from "antd";
import menuList from "../../../../assets/data/menuConfig";
import {
  reqRoleCreate,
  reqRoleUpdatePermission,
  reqRoleDelete,
} from "../../../../assets/api";

function RoleModal(props) {
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 17,
      span: 24,
    },
  };

  const [TreeValue, setTreeValue] = useState();

  const postreqRoleCreate = async (data) => {
    const result = await reqRoleCreate(data);
    if (result.data.success) {
      message.success("创建成功");
      props.getRoleList(props.pagedata.page, props.pagedata.size);
    } else {
      message.error("创建失败！");
    }
  };

  const postRoleUpdatePermission = async (data) => {
    const result = await reqRoleUpdatePermission(data);
    if (result.data.success) {
      message.success("更新成功");
      props.setrole({
        id: data.id,
        name: data.name || props.role.name,
        permission: data.permission || props.role.permission,
      });
      props.getRoleList(props.pagedata.page, props.pagedata.size);
    } else {
      message.error("更新失败");
    }
  };

  const getRoleDelete = async (data) => {
    const result = await reqRoleDelete(data);
    if (result.data.success) {
      message.success(result.data.message);
      props.getRoleList();
    } else {
      message.error("删除失败");
    }
  };

  const onFinish = (values) => {
    if (props.title === "创建角色") {
      let permission = TreeValue || ["/admin/home"];
      let name = values.rolename;
      if (!permission.length) {
        permission = ["/admin/home"];
      }
      if (permission.indexOf("/admin/home") > -1) {
        permission = ["/admin/home", ...permission];
      }
      postreqRoleCreate({ name: name, permission: permission });
    } else {
      let id = props.role.id;
      let name = values.rolename || props.role.name;
      let permission = TreeValue || props.role.permission;
      if (name !== props.role.name || permission !== props.role.permission) {
        postRoleUpdatePermission({
          id: id,
          name: name,
          permission: permission,
          update_user_id:props.userId
        });
      }
    }
    props.setMvisible(false);
  };
  return (
    <div>
      <Modal
        title={props.title}
        visible={props.mvisible}
        destroyOnClose={true}
        key={props.role.id}
        onCancel={() => props.setMvisible(false)}
        footer={null}
      >
        <Form hidden={props.title === "删除角色" ? false : true}>
          <div style={{ margin: "20px" }}>
            您确定要删除
            <span style={{ color: "red" }}>
              {props.role ? props.role.name : null}
            </span>
            吗?
          </div>
          <Form.Item {...tailLayout}>
            <Button
              htmlType="button"
              onClick={() => {
                props.setMvisible(false);
              }}
            >
              取消
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                getRoleDelete({ id: props.role_id ? props.role.id : null });
                props.setMvisible(false);
              }}
              style={{ marginBottom: "20px" }}
            >
              确定
            </Button>
          </Form.Item>
        </Form>

        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ marginTop: "10px" }}
          hidden={props.title === "删除角色" ? true : false}
        >
          <Form.Item
            label="角色名称"
            name="rolename"
            initialValue={props.title === "创建角色" ? "" : props.role.name}
            rules={[{ required: true, message: "角色名称不能为空" }]}
          >
            <Input prefix={<UserOutlined />} style={{ width: "300px" }} />
          </Form.Item>

          <Form.Item label="设置权限">
            <Tree
              checkable
              defaultExpandAll={true}
              treeData={menuList}
              defaultCheckedKeys={props.title === "创建角色" ? [] :(props.role.permission ? props.role.permission : [])}
              onCheck={(checkedKeys) => {
                setTreeValue(checkedKeys);
              }}
            />
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button
              onClick={() => {
                props.setMvisible(false);
              }}
            >
              取消
            </Button>
            &nbsp;&nbsp;
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginBottom: "20px" }}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default RoleModal;
