import React from 'react';
import './index.less';
import {Redirect} from 'react-router-dom';
import {Modal} from 'antd';
import {HoxLogin} from '../../assets/hox';
import {localSet} from '../../assets/zkcyFunc';
import UserNameLogin from './userNameLogin';
import WechartsLogin from './wechartsLogin';


// const currentUser = storageUser.getUser()


export default function Login(props) {
    const {modalIndex,setModalIndex} = HoxLogin();
    
    const user = localSet.getUser();

    if (user.id){
        // props.history.replace ('/main');
        return <Redirect to={{pathname:"/main",query:'/main/manage/static'}}  />
    }

    return (
        <div id="login">
            <div className="header">
                <img 
                    className="img_left"
                    src="http://82.156.100.210/hzs/media/sxhzs/图标文字logo.png"  
                    alt="img_left"
                />
            </div>
            <div className="content">
                <Modal
                    onCancel={()=>{setModalIndex(1)}}
                    visible={(modalIndex === 2)}
                    footer={null}
                    mask={false}
                    centered={true}
                    width={400}
                    >
                    <WechartsLogin/>
                </Modal>
                <Modal
                    visible={(modalIndex === 1)}
                    onCancel={()=>{props.history.goBack()}}
                    footer={null}
                    mask={false}
                    centered={true}
                    width={400}
                    >
                    <UserNameLogin/>
                </Modal>
            </div>
            <div>
            <div className="footer">
                <img 
                    style={{height:'20px',width:'18px'}} 
                    src="http://82.156.100.210/hzs/media/sxhzs/gongan.png"  
                    alt="gonganlogo"
                />
                &nbsp;&nbsp;陕ICP备19008641号
            </div>
            </div>
        </div>
    )
}
