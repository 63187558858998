import React from 'react';
import {
    HashRouter as Router,
    Route,
    Switch,
    Redirect
  } from 'react-router-dom';

import Home from './page/home';
import Login from './page/login';
import Main from './page/main'

export default function App() {
    return (
        <Router>
            <Switch>
                <Route path='/' exact component = { Home }/>
                <Route path='/login' component = { Login } />
                <Route path='/main' component = { Main } />
                <Redirect from="/*" to= '/' />
            </Switch>
        </Router>
    )
}
