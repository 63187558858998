import React,{useState,useEffect} from 'react';
import { Layout, Menu} from "antd";
import { Route, Switch, Redirect } from "react-router-dom";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import './index.less';
import LoginSection from '../home/loginSection';
// import menuList from '../../assets/data/menuConfig';
import { HoxViewport } from "../../assets/hox";
import {localSet,transRoleList} from '../../assets/zkcyFunc';
// import {reqRoleList} from '../../assets/api'

const { Header, Sider, Content, Footer } = Layout;
const { SubMenu } = Menu;

const defaultSubPath = "/main/manage/static";

const getNavList = (list) => {
  return list.map((item) => {
    if (!item.children) {
        return (
          <Menu.Item key={item.key} icon={item.icon} title={item.title}>
            {item.title}
          </Menu.Item>
        );
    } else {
        return (
          <SubMenu key={item.key} icon={item.icon} title={item.title}>
            {getNavList(item.children)}
          </SubMenu>
        );
    }
  });
};

const getRouteList = (list) => {
  return list.map((item) => {
    if (!item.children) {
      return <Route path={item.key} component={item.component} />;
    } else {
      return getRouteList(item.children);
    }
  });
};

export default function Main (props) {

    const [collapsed, setCollapsed] = useState(false);
    // const [menuList, setMenulist] = useState();
    const currentUser = localSet.getUser()
    const menuList = transRoleList(currentUser.permission ||[])

    const defaultKey = props.location.query
      ? props.location.query
      : props.location.pathname
    const [selectedkey, setSelectedkey] = useState(defaultKey);

    const openkey = (key) => {
      return key.slice(0, key.lastIndexOf("/"));
    };

    const changeSubPath = (e) => {
      props.history.push({ pathname: e.keyPath[0] });
      setSelectedkey(e.keyPath[0]);
    };

    const { currentWindowWidth } = HoxViewport();

    useEffect(() => {
      if(currentWindowWidth<1200){
        setCollapsed(true);
      }else{
        setCollapsed(false)
      }
      // getRoleList({ id: currentUser.role_id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWindowWidth]);

    // useEffect(()=>{
    //   getRoleList({ id: currentUser.role_id });
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[])

    // 设置用户登录时限，默认为240分钟
    // const currentUser = localSet.getUser();
    if (currentUser.id) {
            
      let currentStamp = new Date().getTime();
      let onlineMinutes = (currentStamp - currentUser.last_login) / 60000;
      // 时间单位为分钟
      if (onlineMinutes > 240) {
        localSet.removeUser();
        return <Redirect to="/login" />;
      }
    } else {
      return <Redirect to="/login" />;
    }

      if(menuList.length){
        return (
          <div id="main">
            <Layout>
              <Sider
                breakpoint="lg"
                collapsedWidth="0"
                className="nav"
                trigger={null}
                collapsible={false}
                collapsed={collapsed}
              >
                <div className="logo">
                  <img
                    className="left"
                    src="http://82.156.100.210/hzs/media/sxhzs/图标logo.png"
                    alt="logo"
                  />
                  {!collapsed && (
                    <img
                      className="right"
                      src="http://82.156.100.210/hzs/media/sxhzs/暗色文字logo.png"
                      alt="text"
                    />
                  )}
                </div>
                <Menu
                  defaultSelectedKeys={[selectedkey]}
                  defaultOpenKeys={[openkey(selectedkey)]}
                  mode="inline"
                  theme="dark"
                  onClick={changeSubPath}
                >
                  {getNavList(menuList)}
                </Menu>
              </Sider>
              <Layout className="site">
                <Header className="header1">
                    {React.createElement(
                      collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                      {
                        className: "trigger",
                        onClick: () => {
                          setCollapsed(!collapsed);
                        },
                      }
                    )}
                  <LoginSection sub={{ ...props }}></LoginSection>
                </Header>
                <Content className="content1">
                  <Switch>
                    {getRouteList(menuList)}
                    <Redirect to={defaultSubPath} />
                  </Switch>
                </Content>
                <Footer className="footer1">
                  <img
                    style={{ height: "20px", width: "18px" }}
                    src="http://82.156.100.210/hzs/media/sxhzs/gongan.png"
                    alt="gonganlogo"
                  />
                  &nbsp;&nbsp;陕ICP备19008641号
                </Footer>
              </Layout>
            </Layout>
          </div>
      )        
      }else {
        return <div>平台错误：menuList没有被加载......</div>
      }

}
