import React,{useState,useEffect} from 'react';
import './index.less';
import Level from './level'
import City from './city'
import District from './district'
import {reqEchartsGroupAll} from '../../../../assets/api'
import {localSet} from '../../../../assets/zkcyFunc';
import {message} from 'antd'

export default function Analysis() {

    const currentUser = localSet.getUser();
    const [levelData,setLevelData] = useState([])
    const [cityData,setCityData] = useState([])
    const [districtData,setDistrictData] = useState([])

    const getEchartsGroupAll =async (reqData) => {
        const result = await reqEchartsGroupAll(reqData);
        if (result.data.success) {
          setLevelData(result.data.obj.level);
          setCityData(result.data.obj.city);
          setDistrictData(result.data.obj.district);
        } else {
          message.error("请求聚类数据列表失败！");
        }        
    }

    useEffect(()=>{
        getEchartsGroupAll({id:currentUser.id})  
    },[])

    return (
        <div className="yibcbj">
            <Level levelData={levelData}/>
            <City cityData={cityData}/>
            <District districtData={districtData}/>
        </div>
    )
}
