import React from 'react';
import { Form, Button, Input, Select,message } from 'antd';
import {reqAdminCreat} from '../../../../assets/api';



function CreateRoleModal(props) {

    const postAdminCreat = async (data) => {
        const result = await reqAdminCreat(data);
        if (result.data.success) {
          props.setFlag(!props.flag);
        } else {
          message.error("创建管理员账号失败！");
        }
      };

    const [form] = Form.useForm();
    //未做    
    const onFinish = values => {
        let currentStamp = new Date().getTime().toString();

        // console.log('Success:', {...values,openid:currentStamp});
        postAdminCreat({...values,openid:currentStamp});
        props.setShowModalA(false);
        form.resetFields();
    }

    const options = props.roles ? props.roles.map(item => (
        { label: item.name, key: item.id, value: item.id }
    )) : {}

    return (
        <div>
            <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                form={form}
            >
                <Form.Item
                    style={{marginTop:'20px'}}
                    labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}
                    label="用户姓名"
                    name="name"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input style={{ width: '200px' }} />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}
                    label="手机号码"
                    name="phonenumber"
                    rules={[{ 
                            required: true, 
                            pattern: /^1[34578]\d{9}$/,
                            message: '手机号码格式不正确!' 
                          }]}
                >
                    <Input style={{ width: '200px' }} />
                </Form.Item>

                <Form.Item
                    labelCol={{ span: 6 }} wrapperCol={{ span: 10 }}
                    label="角色"
                    name="role_id"
                    normalize={(value) => { return value }}
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Select
                        options={options}
                    ></Select>
                </Form.Item>
                <Form.Item 
                labelCol={{ span: 6 }} wrapperCol={{ span: 20, offset: 2 }}>
                    <div style={{marginBottom:'20px',float:'right'}}>
                    <Button onClick={()=>props.setShowModalA(false)}>取消</Button>
                    &nbsp;&nbsp;
                    <Button type="primary" htmlType="submit">提交</Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
}

export default CreateRoleModal;