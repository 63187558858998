import React,{Fragment} from 'react';
import './index.less'
import ReactEcharts from "echarts-for-react";
import {registerTheme} from 'echarts';
import echartsTheme from "../../../../assets/Echarts/macarons";
import { Spin ,Alert} from 'antd';

const City = (props) => {

   (registerTheme('theme', echartsTheme));
   
   const getOption =()=>{
    return { 

        tooltip: {
            trigger: "axis",
            axisPointer: {    
            },
        },
        color:['#389e0d','#237804'],
        toolbox: {
            show: true,
            feature: {
                magicType: {
                    show: true,
                    type:['line', 'bar'] //显示切换柱状图和折线图
                },
                restore: {show: true}, //刷新的图标
                saveAsImage: {show: true}  //保存为图片
            }
        },
        grid: {
            borderWidth: 0,
            top: 48,
            bottom: 70,
            left:50,
            right:45,
            textStyle: {
                color: "#fff"
            }
        },
        legend: {
            x: '4%',
            left:"center",
            textStyle: {
                color: '#90979c',
            },
            data: ['数量']
        }, 
        xAxis: [{
            type: 'category',
            boundaryGap: true,
            axisLine: {
                lineStyle: {
                    color: '#4488BB',
                    width:2
                }
            },
            inverse:true,
            splitLine: {
                show: true
            },
            axisTick: {
                show: false
            },
            splitArea: {
                show: false
            },
            axisLabel: {
                interval: 0,
                color:'#333',
                textStyle: {
                    color: '#7c8893',
                    fontSize: 12
                },
            },
            data: props.cityData?props.cityData.name:[],
        }],
        yAxis: [{  //左侧Y轴
            // "name":"新老客户",
            type: "value",
            axisLine: {
                lineStyle: {
                    color: '#4488BB',
                    width:2
                }
            },
            splitLine: {
                show: true
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color:'#333',
                interval: 1,    
            },
            splitArea: {
                show: false
            },
        },
        {   
            type: 'value',
            splitLine: {
                show: true
            },
            axisLine: {
                lineStyle: {
                    color: '#4488BB',
                    width:2
                }
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                color:'#333',
                interval: 0,    
            },
            splitArea: {
                show: false
            },
        }],
        dataZoom: [{
            show: true,
            height: 30,
            xAxisIndex: [0],
            bottom: 8,
            start: 0,
            end: 100,
            borderColor:"#4488BB",
            fillerColor:"#edf1cd",
            handleIcon: 'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
            handleSize: '100%',
            handleStyle:{
                borderWidth:1
            },
            textStyle:{
                color:"#4488BB"},  
            }],
        series: [{
                name: "数量",
                yAxisIndex: 0,
                type: "bar",
                smooth:0.4,
                symbolSize:8,
                symbol:'diamond',
                // animation:true,
                itemStyle: {
                    normal: {
                        label: {
                            show: false,
                        }
                    }
                },
                data: props.cityData?props.cityData.doc_count:[],
                markLine: {
                  silent: true,
                  data: [{
                      name: '平均线',
                      type: 'average'                  
                  }], 
              }
            }
        ]
    };
}

    if(props.cityData){
        return (
          <Fragment>
                <div className='tagTitle'>市</div>
              <ReactEcharts 
                    className="tubiao"
                  option={getOption()}
                  />    
          </Fragment>
        );
    }
    else {
        return(
        <Spin tip="Loading...">
            <Alert
            message="提示信息"
            description="图表数据加载中"
            type='info'
            />
        </Spin>        
    )}
}
export default City;
